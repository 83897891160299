:root {
	// backgrounds
	--main-background: rgba(var(--base-02), 1);
	--main-surface-1: rgba(var(--base-01), 1);
	--main-surface-2: rgba(var(--base-02), 1);

	// text
	--main-text: rgba(var(--primary-accent-blue), 1);
	--main-text-supportive: rgba(var(--base-06), 1);
	--main-text-dark: rgba(var(--base-12), 1);
	--main-text-primary: rgba(var(--primary-accent-red), 1);
	--main-text-disabled: rgba(var(--primary-accent-red-dark), 1);

	// icons
	--main-icons: rgba(var(--primary-accent-red, 1));
	--main-action-icons: rgba(var(--primary-accent-red), 1);
	--main-badge: rgba(var(--primary-accent-red), 1);
	--main-on-badge: rgba(var(--base-01), 1);

	// buttons
	--main-primary: rgba(var(--primary-accent-red), 1);
	--main-primary-hover: rgba(var(--primary-accent-red), 1);
	--main-secondary: rgba(var(--primary-accent-red), 1);
	--main-disabled: rgba(var(--primary-accent-red), 1);
	--main-on-primary: rgba(var(--base-01), 1);
	--main-on-secondary: rgba(var(--base-01), 1);
	--main-on-disabled: rgba(var(--primary-accent-red-dark), 1);

	// generic colors
	--generic-overlay: rgba(var(--base-11), 0.8);
	--generic-stroke-10-percent: rgba(0, 0, 0, 10%);

	// gradient
	--surface-to-primary-gradient: rgba(var(--primary-accent-red), 0.15);
	--background-to-surface-gradient: rgba(var(--base-02), 0.6);

	// system colors
	--system-success: var(--success);
	--system-informative: var(--info);
	--system-error: var(--error);

	// radius
	--base-radius-small: 3px;
	--base-radius-medium: 6px;
	--base-radius-large: 12px;
}
