:root {
	--sportsbook-icon-primary: rgba(var(--primary-accent-red), 1);
	--sportsbook-text-accent: rgba(var(--primary-accent-red), 1);

	// Sport page
	--sportsbook-inline-date-picker-title-disabled-color: var(--sportsbook-text-supportive);

	// Betslip
	--sportsbook-betslip-header-bets-count-circle-color: rgba(var(--primary-accent-blue), 1);
	--sportsbook-betslip-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-minimized-betslip-header-bg-color: rgba(var(--primary-accent-red), 1);
	--sportsbook-minimized-betslip-header-bets-count-label-color: rgba(var(--primary-accent-red), 1);
	--sportsbook-betslip-header-icon-color: rgba(var(--primary-accent-blue), 1);
	--sportsbook-betslip-radio-button-color: rgba(var(--base-04), 1);
	--sportsbook-betslip-radio-button-checked-color: rgba(var(--primary-accent-blue), 1);
	--sportsbook-is-prominent-color: rgba(var(--primary-accent-red), 1);
	--sportsbook-betslip-icon-color: var(--sportsbook-icon-primary);

	// Event
	--sportsbook-event-scoreboard-participant-color: rgba(var(--primary-accent-blue), 1);
	--sportsbook-event-scoreboard-score-color: rgba(var(--primary-accent-blue), 1);

	// Event List
	--sportsbook-my-bets-share-button-bg: rgba(var(--base-04), 1);
	--sportsbook-my-bets-share-bet-card-bg: rgba(var(--base-02), 1);

	// Sportsbook CarouselItem
	--sportsbook-carousel-promotion-combo-bg-color: rgba(var(--primary-accent-blue), 1);
	--sportsbook-carousel-promotion-combo-label-color: var(--sportsbook-text-contrast);
	--sportsbook-carousel-promotion-combo-event-name-color: var(--sportsbook-text-contrast);
	--sportsbook-carousel-promotion-link-bg-color: var(--sportsbook-bg-accent);
	--sportsbook-carousel-promotion-link-label-color: var(--sportsbook-text-contrast);
	--sportsbook-carousel-promotion-header-bg-color: rgba(var(--primary-accent-blue, 1));
	--sportsbook-carousel-promotion-header-title-color: var(--sportsbook-text-contrast);
	--sportsbook-carousel-promotion-single-label-color: rgba(var(--primary-accent-blue), 1);

	// Recommended cards
	--sportsbook-recommended-card-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-recommended-card-bg-gradient-top-color: rgba(var(--base-01), 0.15);
	--sportsbook-recommended-card-bg-gradient-bottom-color: rgba(var(--primary), 0.15);
	--sportsbook-recommended-card-header-bg-gradient-left-color: rgba(var(--base-01), 0.5);
	--sportsbook-recommended-card-header-bg-gradient-right-color: rgba(var(--base-01), 0);
	--sportsbook-recommended-card-border-color: rgba(var(--base-12), 0.05);
	--sportsbook-recommended-card-icon-bg-color: rgba(var(--primary), 1);
	--sportsbook-recommended-card-icon-color: rgba(var(--base-01), 1);

	// My bets
	--sportsbook-my-bets-expand-color: var(--base-01);

	// Event Widget
	--sportsbook-event-widget-button-color: rgba(var(--base-08), 1);

	// Other
	--sportsbook-live-color: rgba(var(--primary-accent-red-light), 1);
	--sportsbook-desktop-menu-chevron-color: rgba(var(--primary-accent-red), 1);
	--sportsbook-browse-link-icon-color: rgba(var(--primary-accent-red), 1);
	--sportsbook-event-statistics-icon-color: rgba(var(--primary-accent-blue), 1);
	--sportsbook-event-dropdown-icon-color: rgba(var(--primary-accent-red), 1);
	--sportsbook-navigation-tab-disabled-color: rgba(var(--base-04), 1);
	--sportsbook-border-secondary: rgba(var(--base-03), 1);

	// Share betslip
	--sportsbook-share-betslip-copy-input-bg: rgba(var(--base-02), 1);
	--sportsbook-betslip-freebet-select-bg: var(--sportsbook-betslip-freebet-select-bg);
}
