@use 'custom-properties';
@use 'semantic-variables';
@use 'sportsbook';

// base/type

body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
label,
legend,
button,
input,
select,
textarea,
li,
a,
strong,
span,
th,
caption,
td,
p,
footer,
small {
	color: rgba(var(--font-brand-02), 1);
	font-family: haboro-soft, sans-serif;
}

body {
	background-color: rgba(var(--base-02), 1);

	&.access-blocked {
		background-color: rgba(var(--primary-accent-grey-light), 1);

		h2 {
			color: rgba(var(--font-brand-02), 1);
			font: var(--h2);
		}

		p {
			color: rgba(var(--font-supportive-01), 1);
		}

		a {
			color: rgba(var(--primary-accent-red), 1);
		}
	}
}

// components/bonus-active

.menu {
	&.activity-item-list-container {
		.page-head {
			background-color: rgba(var(--base-01), 1);

			.page-head__title {
				color: rgba(var(--primary-accent-blue), 1);
			}
		}

		.page-head__back {
			svg {
				fill: rgba(var(--primary-accent-red), 1);
			}
		}
	}
}

.bonus-active {
	h2 {
		color: rgba(var(--base-12), 1);
	}

	.offer-tag {
		svg {
			fill: rgba(var(--primary-accent-blue), 1);
		}
	}
}

.progress {
	.progress__number,
	.progress__number span {
		color: rgba(var(--base-01), 1);
	}

	svg {
		path {
			stroke: rgba(var(--base-02), 1);
		}

		.progress__meter {
			stroke: rgba(var(--primary-accent-red), 1);
		}
	}
}

// components/buttons

.btn {
	background-color: rgba(var(--primary-accent-red), 1);
	color: rgba(var(--base-01), 1);

	&:disabled {
		background-color: rgba(var(--primary-accent-red), 1);
		color: rgba(var(--primary-accent-red-dark), 1);
	}

	&.btn--secondary {
		background-color: rgba(var(--base-02), 1);
		color: rgba(var(--primary-accent-blue), 1);

		&:disabled {
			color: rgba(var(--font-disabled), 1);
		}
	}

	&.btn--tertiary {
		background: none;
		color: rgba(var(--primary-accent-blue), 1);
	}

	&.btn--clear,
	&.btn--login,
	&.btn--signup,
	&.btn--skip {
		color: rgba(var(--primary-accent-red), 1);
	}

	&.btn--applepay {
		-apple-pay-button-style: black;
	}

	&.btn--light {
		background-color: rgba(var(--primary-accent-red), 1);
		color: rgba(var(--base-01), 1);
	}

	&.btn--inverted {
		color: rgba(var(--primary-accent-red), 1);
	}

	&.btn--visit {
		background-color: rgba(var(--base-01), 1);
		color: rgba(var(--primary-accent-blue), 1);
	}

	&.btn--action-screen-confirm {
		background-color: rgba(var(--primary-accent-red), 1);
		color: rgba(var(--font-light), 1);
	}

	&.btn--responsible-gaming-logout {
		background-color: transparent;
		color: rgba(var(--primary-accent-red), 1);
	}

	&.btn--transaction-history-reality-check,
	&.btn--disable-reality-check {
		background-color: transparent;
		border: solid 1px rgba(var(--primary-accent-green), 1);
		color: rgba(var(--primary-accent-green), 1);
	}
}

// components/campaign

.campaign__item {
	.description {
		color: rgba(var(--primary-accent-blue-light), 1);
	}
}

// components/cc-form

.cc-form,
li.deposit-limit {
	&:focus-within .active-border {
		border-bottom: 2px solid rgba(var(--primary), 1);
	}
}

// components/detail-accordion

.details-accordion {
	.summary-icon::after {
		filter: brightness(30%) sepia(1) hue-rotate(7deg) saturate(22);
	}
}

// components/filters

.user-options {
	background-color: rgba(var(--base-01), 1);
}

.filters {
	input {
		+ .filters__filter {
			background-color: rgba(var(--primary-accent-blue), 1);
			border-bottom: 1px solid rgba(var(--base-04), 1);
			color: rgba(var(--font-dark), 1);
		}

		&:checked {
			+ .filters__filter {
				border-bottom: 0;
				color: rgba(var(--primary-accent-red), 1);
				font: var(--label-bold);
			}
		}
	}

	.sliding-border {
		background-color: rgba(var(--primary-accent-red), 1);
		height: 4px;
	}
}

// components/form

:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus {
	box-shadow: inset 0 0 0 9999px rgba(var(--base-01), 1) !important;
	-webkit-text-fill-color: #182238 !important;
}

.slider-container {
	svg {
		fill: rgba(var(--primary-accent-red), 1);
	}
}

.input-wrap {
	.input-wrap__input {
		border-bottom: 1px solid rgba(var(--primary-accent-grey-dark), 1);
		color: rgba(var(--font-dark), 1);

		&[type='password']:not(:placeholder-shown) {
			font: large Verdana, sans-serif;
		}
	}

	&::after {
		background-color: rgba(var(--base-04), 1);
	}

	.slider-input {
		background-color: rgba(var(--base-02), 1);
	}
}

.expiry-wrap.add-slash::after {
	color: rgba(var(--font-dark), 1);
}

.legend,
.input-wrap__label {
	color: rgba(var(--font-supportive-01), 1);
}

::-webkit-input-placeholder {
	color: rgba(var(--font-supportive-01), 1);
}

::-moz-placeholder {
	color: rgba(var(--font-supportive-01), 1);
}

:-ms-input-placeholder {
	color: rgba(var(--font-supportive-01), 1);
}

// New checkboxes

.custom-checkbox-list {
	li {
		border-bottom: 1px solid rgba(var(--base-02), 1);
	}
}

.toggle-password {
	svg {
		fill: rgba(var(--primary-accent-red), 1);
	}
}

// component/game-category
// components/game-item

.item__urgent {
	background-color: rgba(var(--primary-accent-red-dark), 1);

	* {
		color: rgba(var(--base-01), 1);
	}
}

// components/game-list

.item__footer {
	border-top: 1px solid rgba(var(--base-04), 1);

	.item__love {
		border-right: 1px solid rgba(var(--base-04), 1);

		.item__love-icon {
			fill: rgba(var(--base-05), 1);
		}
	}

	.item__love-counter,
	.item__provider-title {
		color: rgba(var(--base-05), 1);
	}
}

.item__love.game-item__love--selected {
	svg.item__love-icon {
		fill: rgba(var(--primary-accent-red), 1);
	}

	.item__love-counter {
		color: rgba(var(--primary-accent-red), 1);
	}
}

.item__live-casino {
	border-bottom: 1px solid rgba(var(--base-03), 1);

	.last-winning-number-container {
		.number-to-appear-text,
		.number-to-disappear-text {
			color: rgba(var(--base-02), 1);
			font: var(--caption);
		}
	}
}

.game-item__jackpot {
	background-color: rgba(var(--base-02), 1);
}

// components/

.account-icon-header {
	color: rgba(var(--base-02), 1);
}

.page-head {
	background-color: rgba(var(--primary-accent-blue), 1);

	.svg-logo {
		width: 101px;
	}

	.btn--login {
		color: rgba(var(--base-02), 1);
	}

	.page-head__title {
		color: rgba(var(--primary-accent-blue), 1);
	}

	svg {
		fill: rgba(var(--primary-accent-blue), 1);
	}

	&.u-bg-trans,
	&.country-select-header {
		.page-head__title {
			color: rgba(var(--primary-accent-blue), 1);
		}

		svg {
			fill: rgba(var(--primary-accent-red), 1);
		}
	}
}

// components/link-list

.link-list {
	.link-list__item {
		background-color: rgba(var(--base-02), 1);

		&--rounded {
			border-radius: 3px;
		}

		svg {
			fill: rgba(var(--primary-accent-red), 1);
		}

		.menu__main-title,
		.link-list__preamble {
			color: rgba(var(--primary-accent-blue), 1);
		}

		.link-list__continue-icon {
			fill: rgba(var(--primary-accent-red), 1);
		}

		.link-list__more-icon {
			height: 20px;
			width: 20px;
		}

		.link-list__value {
			color: rgba(var(--primary-accent-blue-light), 1);
		}

		.link-list__status-verified {
			color: rgba(var(--primary-accent-red), 1);
		}

		.link-list__status-unverified {
			color: rgba(var(--base-08), 1);
		}
	}
}

// components/list-options

.list-options {
	background-color: rgba(var(--base-01), 1);

	.link-list {
		.link-list__item {
			background-color: transparent;
			border-bottom: 1px solid rgba(var(--base-03), 1);
		}
	}
}

// components/my notifications

.cta-button-container {
	background: rgba(var(--base-11), 1);
}

// components/notify

.notification-wrap {
	box-shadow: 0 0 4px black;
	color: rgba(var(--base-02), 1);

	.page-head__close {
		svg {
			fill: #fff;
		}
	}

	p,
	.notification-wrap__error-text-msg {
		color: rgba(var(--base-02), 1);
	}

	.notification-wrap__error-text-header {
		color: rgba(var(--base-02), 1);
	}

	.header-icon {
		svg {
			fill: #fff;
		}
	}

	&.red {
		background-color: rgba(var(--error), 1);
	}

	&.green {
		background-color: rgba(var(--success), 1);
	}

	&.neutral {
		background-color: rgba(var(--info), 1);
	}
}

//notification - inline
.notification-inline-wrap {
	&.red {
		background-color: rgba(var(--error), 1);
	}

	&.green {
		background-color: rgba(var(--success), 1);
	}

	&.neutral {
		background-color: rgba(var(--info), 1);
	}
}

// BINARY QUESTION MODAL

.binary-question {
	.binary-question__inner {
		background-color: rgba(var(--base-01), 1);

		.binary-question__main-title,
		.binary-question__p,
		.binary-question__button-container .binary-question__yes,
		.binary-question__button-container .binary-question__no {
			color: rgba(var(--font-dark), 1);
		}
	}

	.binary-question__button-container {
		.binary-question__yes,
		.binary-question__no,
		.binary-question__third {
			background: none;
		}
	}
}

// NOTIFY

.notify {
	&.notify--is-open {
		section {
			svg {
				fill: rgba(var(--base-02), 1);
			}

			h2,
			p {
				color: rgba(var(--base-02), 1);
			}
		}
	}

	&.notify--success {
		background-color: rgba(var(--success), 1) !important;
	}

	&.notify--failure {
		background-color: rgba(var(--error), 1) !important;
	}
}

.notify__head {
	.notify__head--quick-deposit {
		fill: var(--section-head-main-icon);
	}
}

// PROGRESS BAR

.notify__progress-bar {
	background-color: rgba(var(--base-11), 1);

	&.notify__progress-bar--fill-success {
		background-color: rgba(var(--success), 1);
		background-image: none;
	}

	&.notify__progress-bar--fill-fail {
		background-color: rgba(var(--error), 1);
		background-image: none;
	}
}

// OPTION BOX

// OVERLAY BEHIND MODAL

.deposit-siru,
.binary-question {
	&.notify--is-open {
		background-color: rgba(var(--base-11), 0.85);
	}
}

// COOKIE CONSENT

// PAYMENT METHODS

.payment-methods {
	&__item {
		background-color: rgba(var(--primary-accent-grey-light));

		&__logo {
			svg.logo-payment-method {
				background-color: #fff;
			}
		}

		&__chevron {
			svg.link-list__continue-icon {
				fill: rgba(var(--primary-accent-red), 1);
			}
		}
	}
}

.js-notify__button-wrapper--failed {
	.btn.btn--secondary {
		margin-bottom: 12px;
	}

	svg {
		fill: rgba(var(--primary-accent-red), 1);
	}
}

.payment-reminder {
	color: rgba(var(--primary-accent-blue));
	padding: 6px 0;
	white-space: pre-line;
}

// Two Factor auth on execute deposit

.two-fact {
	&__error-label {
		color: rgba(var(--error), 1);
	}

	.btn:disabled {
		background-color: inherit;
	}
}

.notify--is-open {
	&.reality-check-confirm {
		background-color: rgba(var(--base-01), 1) !important;
		background-image: none;
	}
}

.option-box {
	background-color: rgba(var(--primary-accent-blue), 0.85);

	.option-box__inner {
		border-bottom: 1px solid rgba(var(--primary-accent-blue), 1);
	}

	.btn--secondary {
		background-color: rgba(var(--base-03), 1);
	}

	.user-options {
		background-color: rgba(var(--base-03), 1);

		.filters {
			input {
				//TODO: fix this, reduce nesting REV-318
				//stylelint-disable-next-line selector-max-compound-selectors
				+ .filters__filter {
					background-color: rgba(var(--primary-accent-blue), 1);
					color: rgba(var(--base-01), 1);
				}
				//TODO: fix this, reduce nesting REV-318
				//stylelint-disable-next-line selector-max-compound-selectors
				&:checked {
					//TODO: fix this, reduce nesting REV-318
					//stylelint-disable-next-line selector-max-compound-selectors
					+ .filters__filter {
						background-color: rgba(var(--primary-accent-red), 1);
						color: rgba(var(--base-02), 1);
					}
				}
			}
		}
	}
}

.binary-question {
	.binary-question__inner {
		background-color: rgba(var(--base-01), 1);

		&.u-box-bg {
			background-color: rgba(var(--base-01), 1);
		}
	}

	.input-wrap__input,
	.two-fact__input-wrap input {
		background-color: rgba(var(--base-02), 1);
	}

	.binary-question__yes,
	.binary-question__no,
	.binary-question__third {
		border-top: 1px solid rgba(var(--base-04), 1);
	}

	.binary-question__yes {
		border-left: 1px solid rgba(var(--base-04), 1);
		border-top: 1px solid rgba(var(--base-04), 1);
	}
}

// components/quick-deposit

.quick-deposit-wrapper {
	background-color: rgba(var(--base-01), 1);

	.page-head__back {
		fill: rgba(var(--primary-accent-blue), 1);
	}

	.icon-payment {
		fill: rgba(var(--base-02), 1);
	}
}

.quick-deposit-response {
	&.quick-deposit-response--pending {
		background-color: rgba(var(--base-10), 1);
	}
}

// components/scroll-page-head

#sidebar .page-head {
	background-color: rgba(var(--base-01), 1);
}

.page-head {
	background-color: var(--head-bg);

	.btn--login,
	.page-head__title {
		color: rgba(var(--primary-accent-blue), 1);
	}

	.btn--skip {
		color: rgba(var(--primary-accent-red), 1);
	}

	svg {
		fill: rgba(var(--primary-accent-red), 1);
	}

	&.u-bg-trans {
		.page-head__title {
			color: rgba(var(--primary-accent-blue), 1);
		}

		.btn--skip,
		.btn--login {
			color: rgba(var(--primary-accent-red), 1);
		}

		svg {
			fill: rgba(var(--primary-accent-red), 1);
		}
	}
}

// components/select

.custom-select {
	background-color: rgba(var(--primary-accent-grey-light), 1);
	border-bottom: 1px solid rgba(var(--base-02), 1);

	select.placeholder-active {
		color: rgba(var(--primary-accent-blue), 1);
	}

	option {
		color: rgba(var(--primary-accent-blue), 1);
	}
}

// components/svg-logo
.svg-logo {
	.saga-fixed {
		fill: rgba(var(--primary-accent-red), 1);
	}

	.saga-top {
		fill: rgba(var(--font-light), 1);
	}

	.saga-bottom {
		fill: rgba(var(--font-supportive-03), 1);
	}
}

.page-head__title {
	// logo on top of white bg
	.svg-logo {
		.saga-top {
			fill: rgba(var(--primary-accent-blue), 1);
		}

		.saga-bottom {
			fill: rgba(var(--primary-accent-red), 1);
		}
	}
}

.svg-logo--dark {
	.saga-fixed {
		fill: rgba(var(--primary-accent-red), 1);
	}

	.saga-top {
		fill: rgba(var(--primary-accent-blue), 1);
	}

	.saga-bottom {
		fill: rgba(var(--primary-accent-red), 1);
	}
}

// components/spinner
// components/support-nav

.dots-container {
	svg {
		fill: rgba(var(--primary-accent-red), 1);
	}
}

.support-nav {
	.support-nav__head {
		h2,
		h3 {
			color: rgba(var(--font-dark), 1);
		}
	}

	.btn--secondary {
		background-color: rgba(var(--base-01), 1);
		color: rgba(var(--font-dark), 1);
	}

	&.support-nav--is-open {
		background-color: rgba(var(--opacity-overlay), 0.85);
	}
}

// components/proposed-locale-nav

.propose-locale-nav {
	.support-nav__head {
		color: rgba(var(--propose-locale-text), 1);
	}

	.propose-locale-wrapper {
		border-radius: 3px;

		.btn--secondary {
			border-bottom: 0;
			border-top: 1px solid rgba(var(--propose-locale-border, var(--base-07)), 1);
		}
	}
}

// components/tables

td {
	border-bottom: 1px solid rgba(var(--base-02), 1);

	&.u-text-r,
	p {
		color: rgba(var(--font-brand-02), 1);
	}
}

// components/tabs

.tab {
	.icon--sprite {
		fill: var(--product-tab-content);
	}

	[aria-selected='true'] {
		* {
			color: rgba(var(--font-light), 1);
		}

		svg {
			fill: rgba(var(--font-light), 1);
		}
	}
}

// components/winners
// layouts/bundles

//layouts/bonus terms & condition
.bonus-terms-wrapper {
	.terms-header__text-color {
		color: rgba(var(--primary-accent-grey-light), 1);
	}
}

// layouts/deposit

.bonus-item__image-wrapper {
	background: var(--brandContainerBackground);
	background-size: 50px;

	.offer-tag {
		background-color: rgba(var(--base-03), 1);
	}
}

.deposit-bonus-caption {
	color: rgba(var(--primary-accent-blue-light), 1);
}

// layouts/footer

.paragraph-footer,
.footer-legal-content {
	color: var(--footer-text);

	small {
		color: var(--footer-text);
	}

	a {
		color: var(--footer-text-link);
	}
}

footer .mga-logo {
	color: rgba(var(--base-01), 1);
}

.other-logos-footer {
	&.not-payment-logos-footer li {
		background-color: transparent;
	}
}

// layouts/info-box

.info-box {
	.info-box-text,
	.info-box-value {
		color: rgba(var(--base-09), 1);
	}
}

// layouts/log-in

.bottom-wrapper {
	&.log-in--open {
		background-color: rgba(var(--sidebar-bg), 1);
	}
}

// layouts/modal-view

.modal-view {
	background-color: rgba(var(--base-01), 1);

	.page-head {
		background-color: inherit;
	}

	&.modal-view--verified {
		background-color: rgba(var(--success), 1);
	}
}

// layouts/my-limits

.my-limits-menu {
	.limits-notification-wrapper {
		background-color: rgba(var(--base-02), 1);

		.limits-notification-message-wrapper {
			span {
				color: rgba(var(--primary-accent-blue), 1);
			}

			small {
				color: rgba(var(--base-06), 1);
			}
		}

		.limits-notification-icon-wrapper .limits-notification-icon {
			fill: rgba(var(--primary-accent-red), 1);
			//TODO: fix this, reduce nesting REV-318
			//stylelint-disable-next-line selector-max-compound-selectors
			.icon-close--small svg {
				fill: rgba(var(--primary-accent-red), 1);
			}
		}
	}

	.accordion {
		.accordion__link {
			background-color: rgba(var(--base-02), 1);

			&::before,
			&::after {
				color: rgba(var(--primary-accent-red), 1);
			}
		}

		.accordion__content {
			background-color: rgba(var(--base-02), 1);
		}
	}

	.link-list__item {
		svg {
			fill: rgba(var(--primary-accent-red), 1);

			&:last-child {
				fill: rgba(var(--primary-accent-red), 1);
			}
		}
	}

	button {
		color: rgba(var(--font-brand-01), 1);
	}
}

// layouts/off-canvas

.global-menu,
.account-menu {
	background-color: rgba(var(--base-01), 1);

	.page-head {
		background-color: inherit;

		svg {
			fill: rgba(var(--primary-accent-red), 1);
		}

		.page-head__title {
			color: rgba(var(--primary-accent-blue), 1);
		}
	}

	.user-balance__sum {
		font: var(--label-bold);
	}
}

// layouts/off-canvas-account

.user-balance {
	.user-balance__title {
		color: rgba(var(--blue-300), 1);
	}
}

.session-timer {
	color: rgba(var(--base-04), 1);

	&.session-timer--regulation-header {
		color: rgba(var(--base-12), 1);

		.session-timer-time {
			color: inherit;
		}
	}
}

// layouts/risk-level

.risk-profile {
	.detail-view-header {
		p {
			color: rgba(var(--font-supportive), 1);
		}
	}

	.risk-profile__description {
		td {
			border-bottom: 1px solid rgba(var(--base-02), 1);
		}
	}

	.risk-level-detail-text {
		color: rgba(var(--base-06), 1);
	}
}

// layouts/payment

.summary-table {
	tfoot tr,
	tbody tr {
		td:first-child {
			font: var(--label-bold);
		}

		td[data-at^='summary-table'] {
			color: rgba(var(--font-supportive-02), 1);
		}
	}
}

.link-list__method {
	font: var(--label-bold);
}

.link-list__value span {
	color: rgba(var(--font-supportive-01), 1);
	font: var(--label-regular);
}

// layouts/pusher
// layouts/recover-password
// layouts/result

.result {
	&.result--is-open {
		svg {
			fill: rgba(var(--base-01), 1);
		}

		.nfs-bg svg {
			fill: rgba(var(--primary-accent-blue), 1);
		}

		svg {
			fill: rgba(var(--base-01), 1);
		}
	}
}

.js-payment-result-information-message {
	.js-try-again-button-header {
		color: rgba(var(--primary-accent-red), 1);
	}
}

.payment-result-header {
	h3[data-at='payment-result-header-title-main'] {
		color: rgba(var(--font-brand-02), 1);
	}

	p {
		color: rgba(var(--font-supportive-02), 1);
	}
}

.failed-deposit {
	*:not(.btn),
	.btn- {
		color: rgba(var(--primary-accent-blue), 1);
	}

	svg {
		fill: rgba(var(--primary-accent-blue), 1);
	}

	.payment-result__icon img,
	.payment-methods__item__chevron img {
		filter: rgba(var(--primary-accent-blue), 1);
	}

	.btn--secondary {
		color: rgba(var(--primary-accent-blue), 1);
	}
}

.wrapper-casino {
	.result--is-open {
		.btn--secondary {
			color: rgba(var(--base-01), 1);
		}
	}
}

.payment__form {
	.ReactCreditCard {
		&__display {
			color: rgba(var(--primary-accent-blue), 1);
			opacity: 1;
		}
	}
}

// layouts/shop

.wrapper-shop,
.wrapper-promotions {
	background-color: rgba(var(--base-02), 1);

	.shop-detail-header {
		svg {
			fill: rgba(var(--base-01), 1);
		}
	}

	.seo-content {
		background-color: rgba(var(--primary-accent-blue), 1);
	}
}

.points__svg {
	fill: rgba(var(--primary-accent-blue), 1);

	text {
		fill: rgba(var(--base-02), 1);
	}
}

.offer-tag {
	svg {
		fill: rgba(var(--primary-accent-blue), 1);
	}
}

// layouts/signup

.bottom-wrapper {
	background-color: rgba(var(--base-01), 1);
}

// layouts/sportsbook
// layouts/static-text

.wrapper-about,
.wrapper-responsible-gaming,
.wrapper-privacy_policy,
.wrapper-terms-and-conditions,
.wrapper-cookie-policy {
	background-color: rgba(var(--base-01), 1);

	.adaptable-header--pinned {
		--page-head-title: transparent;
		--page-head-link: rgba(var(--primary-accent-red), 1);
		--page-head-icon: rgba(var(--primary-accent-red), 1);

		background-color: rgba(var(--base-01), 1);
	}
}

.static-text.terms-conditions {
	.page-head {
		background-color: rgba(var(--base-01), 1);

		&.u-bg-trans {
			background-color: var(--sidebar-bg);
		}
	}
}

// layouts/titles

.support-title {
	color: rgba(var(--base-06), 1);
}

// layouts/transaction-history

.transaction-history-inactive-layer {
	background-color: rgba(var(--primary-accent-blue), 1);
}

.transaction-modal {
	background-color: rgba(var(--base-01), 1);
}

// layouts/upload

.template-document-wrapper {
	border-bottom: 1px solid rgba(var(--base-02), 1);

	&::after {
		background-color: rgba(var(--primary-accent-blue), 1);
	}
}

// layouts/welcome-screen

.user-balance-box {
	background-color: rgba(var(--base-01), 1);

	.welcome-balance-text,
	.welcome-balance-amount {
		color: rgba(var(--primary-accent-blue), 1);
	}
}

.welcome-menu {
	.accordion {
		.accordion__link {
			background-color: rgba(var(--base-03), 0.25);
		}

		.accordion__content {
			background-color: rgba(var(--base-03), 0.25);
		}
	}
}

// layouts/withdrawal

.pending-withdrawal {
	border-bottom: 1px solid rgba(var(--base-03), 1);

	.pending-withdrawal__action {
		.pending-withdrawal__cancel-icon {
			fill: rgba(var(--primary-accent-blue-dark), 1);
		}
	}
}

// layouts/SEO content

.seo-content {
	.seo-content__color,
	* {
		color: rgba(var(--base-01), 1);

		h1,
		h2,
		h3,
		h4 {
			color: rgba(var(--base-01), 1);
		}
	}

	a {
		color: rgba(var(--extra-blue-dark), 1);
	}
}

// utils/placeholders
// utils/utility-classes

.u-box-bg {
	background-color: rgba(var(--base-01), 1);
}

.u-text-grey {
	color: rgba(var(--base-06), 1);
}

.u-divider {
	border-color: rgba(var(--base-02), 1);
}

.session-timer {
	color: rgba(var(--primary-accent-blue), 1);
}

// components/payment/link-list
// Payment-provider tags

.payment-tag {
	color: rgba(var(--primary-accent-white), 1);
	font-weight: 800;

	&__popular {
		background-color: rgba(var(--primary-accent-red), 1);
	}

	&__no-fee {
		background-color: rgba(var(--primary-accent-blue), 1);
	}
}

.disclaimer-text {
	color: rgba(var(--primary-accent-red), 1);
	text-decoration: underline;
}

.border-social-media {
	border: 1px solid rgba(var(--social-media-button-background), 1);
}

// Transaction History

.selected-filter-tags {
	span.selected-filter-tags_text {
		color: rgba(var(--filtering-pill-font-color-dark), 1) !important;
	}

	button::after {
		color: rgba(var(--filtering-pill-font-color-dark), 1) !important;
	}
}

.pill.disable span {
	color: rgba(var(--filtering-pill-font-color-dark), 1) !important;
}
